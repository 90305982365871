import { graphql, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import BoxStaticPageList from '../../../../src/common/components/BoxStaticPageListMarcas/BoxStaticPageListMarcas'
import SelectorContainer from '../../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../../src/common/components/FunnelSelector/SelectorSteps'
import Layout from '../../../../src/common/components/Layout'
import MarcasLogo from '../../../../src/common/components/MarcasLogo/MarcasLogo'
import SearchNeumaticos from '../../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../../src/common/components/Title'
import { reset } from '../../../../src/common/context/actions/selectorActions'
import { IAppState } from '../../../../src/common/context/context'
import { selectSkipInfo } from '../../../../src/common/context/selectors/selectorSelectors'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import { locale, t } from '../../../../src/common/i18n'
import { getImages } from '../../../../src/common/utils/imagesS3'
import route from '../../../../src/common/utils/route'
import styles from './index.module.scss'
import { SeoData } from '../../../../src/common/types/Seo'
import { MenuPrincipal } from '../../constants/menu'

const placeholderImage = {
  Key: '',
  url: '',
  link: '',
  alt: '',
}
const ImageFurgoneta = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/neumaticos-furgoneta-elpaso2000.jpg`
const ImagePremium = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/neumaticos-premium-elpaso2000.jpg`
const ImageSeguro = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/seguro-neumatico-elpaso2000.jpg`
const ImageMoto = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/neumaticos-moto-elpaso2000.jpg`
const Image4x4 = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/neumaticos-4x4-elpaso2000.jpg`
const ImageReparacionPinchazo = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/reparacion-pinchazos-elpaso2000.jpg`
const ImageEquilibrado = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/equilibrado-neumaticos-elpaso2000.jpg`
const ImageAlineado = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/alineado-neumaticos-elpaso2000.jpg`
const ImageQuality = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/neumaticos-quality-elpaso2000.jpg`
const ImageEconomicos = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/neumaticos-economicos-elpaso2000.jpg`
const ImageQuad = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/neumaticos-quad-elpaso2000.jpg`

const section = [
  {
    title: `${t('landing_marcas.section1.header')}`,
    items: [
      {
        title: `${t('landing_marcas.section1.item1.title')}`,
        description: `${t('landing_marcas.section1.item1.text')}`,
        img: ImageEconomicos,
        route: 'landing-economy',
      },
      {
        title: `${t('landing_marcas.section1.item2.title')}`,
        description: `${t('landing_marcas.section1.item2.text')}`,
        img: ImageQuality,
        route: 'landing-quality',
      },
      {
        title: `${t('landing_marcas.section1.item3.title')}`,
        description: `${t('landing_marcas.section1.item3.text')}`,
        img: ImagePremium,
        route: 'landing-premium',
      },
    ],
  },
  {
    title: `${t('landing_marcas.section2.header')}`,
    items: [
      {
        title: `${t('landing_marcas.section2.item1.title')}`,
        description: `${t('landing_marcas.section2.item1.text')}`,
        img: Image4x4,
        route: 'landing-4x4',
      },
      {
        title: `${t('landing_marcas.section2.item2.title')}`,
        description: `${t('landing_marcas.section2.item2.text')}`,
        img: ImageFurgoneta,
        route: 'landing-furgoneta',
      },
      {
        title: `${t('landing_marcas.section2.item3.title')}`,
        description: `${t('landing_marcas.section2.item3.text')}`,
        img: ImageMoto,
        route: 'landing-moto',
      },
      {
        title: `${t('landing_marcas.section2.item4.title')}`,
        description: `${t('landing_marcas.section2.item4.text')}`,
        img: ImageQuad,
        route: 'landing-quad',
      },
    ],
  },
  {
    title: `${t('landing_marcas.section3.header')}`,
    items: [
      {
        title: `${t('landing_marcas.section3.item1.title')}`,
        description: `${t('landing_marcas.section3.item1.text')}`,
        img: ImageEquilibrado,
        route: 'neumaticos.equilibrado',
      },
      {
        title: `${t('landing_marcas.section3.item2.title')}`,
        description: `${t('landing_marcas.section3.item2.text')}`,
        img: ImageAlineado,
        route: 'neumaticos.alineado',
      },
      {
        title: `${t('landing_marcas.section3.item3.title')}`,
        description: `${t('landing_marcas.section3.item3.text')}`,
        img: ImageReparacionPinchazo,
        route: 'neumaticos.pinchazos',
      },
      {
        title: `${t('landing_marcas.section3.item4.title')}`,
        description: `${t('landing_marcas.section3.item4.text')}`,
        img: ImageSeguro,
        route: 'neumaticos.seguro',
      },
    ],
  },
]

interface Props {
  data: {
    seoData: SeoData
  }
}

const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('neumaticos'),
        temporada: {
          selectable: process.env.GATSBY_WEB === 'rodi',
          value: 'verano',
        },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <div>
      <div className={styles.mobile}>
        <SearchNeumaticos />
      </div>
      <div className={styles.desktop}>
        <div className={styles.selector_container}>
          <SelectorContainer showMarcas={false} origin={route('neumaticos')}>
            {({ ...props }) => (
              <SelectorForm
                showTemporada={process.env.GATSBY_WEB === 'rodi'}
                fromModal={false}
                handleSubmit={() => {
                  const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                  navigate(next.routes.desktop)
                }}
                {...props}
              />
            )}
          </SelectorContainer>
        </div>
      </div>
    </div>
  )
}

const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

const Neumaticos = ({ data }: Props) => {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
        },
      ]}>
      <div className={styles.header}>
        <Title text={t('landing_marcas.title')} />
        <Selector styles={styles} />
        <div className={styles.banner}>
          <ResponsiveSliderBanner
            desktopImages={banners_desktop}
            mobileImages={banners_mobile}
            sizesVariant={'banner_neumaticos'}
          />
        </div>
        <p>
          {t('landing_marcas.intro.intro1')}
          <strong>{t('landing_marcas.intro.bold')}</strong>
          {t('landing_marcas.intro.intro2')}
        </p>
        <MarcasLogo />
      </div>
      <div className={styles.header}>
        {section.map((section) => (
          <BoxStaticPageList
            key={section.title}
            title={section.title}
            items={section.items}
          />
        ))}
        <br />
        <br />
        <br />
      </div>
    </Layout>
  )
}

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}

export default Neumaticos

export const pageQuery = graphql`
  query marcasQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }

    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: "landings/neumaticos/banners/ca/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: "landings/neumaticos/banners/ca/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: "landings/neumaticos/banners/es/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: "landings/neumaticos/banners/es/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
